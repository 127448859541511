@import '../../Stylesheet/abstracts/variables';

.ProjectCard {
  &.-head {
    max-width: 300px;
    min-width: 300px;
    background-color: #f7f7f7;
    box-shadow: 0px 4px 9px 5px #00000029;
    border-radius: 15px;
    margin: 20px 0px 0px 20px;
    cursor: pointer;
    transition: all 0.4s ease;

    &:hover {
      box-shadow: none;
    }
  }

  &.-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $brand-color-darker;
    min-height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: right;

    & button {
      margin-top: 20px;
      margin-right: 20px;
      max-height: 22px;
      padding: 7px 20px;
      padding-bottom: 18px;
      font-size: 12px;
      border: 1px solid #fff;
      background-color: #60c0edb8;
    }
  }

  &.-body {
    padding: 20px 30px;

    &-title {
      line-height: 1.5rem;
    }
  }
}
