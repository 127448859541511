// FONT FAMILY **/
$font-family-base: 'Avenir', 'Helvetica Neue', 'Roboto', sans-serif;

// BREAKPOINTS **/
$breakpoint-tiny-screens: 375px;
$breakpoint-small-screens: 450px;
$breakpoint-tablet: 600px;
$breakpoint-desktop: 1000px;
$breakpoint-bs-sm: 576px;
$breakpoint-bs-md: 768px;
$breakpoint-bs-lg: 992px;
$breakpoint-bs-xl: 1200px;
$breakpoint-header: 850px;

// COLORS **/
$brand-color: #60c0ed;
$brand-color-dark: #2294e3;
$brand-color-darker: #03182d;
$brand-color-light: #f4faff;
$brand-cool-blue: #264d60;
$brand-color-card: #499ff7;
$brand-orange: #ffc533;
$brand-text-color: #5c5c5c;
$brand-input-placeholder: #5c5c5c9c;
$brand-input-disabled: #c4c4c446;
$brand-input-border: #839aa8;
$brand-lemon: #9dcb3b;
$brand-lemon-lighter: #e7f5e2;
$color-success: #41b661;
$color-danger: #b80202;
$red: #761717;

$white: #fff;
$grey: #4a4a4a;
$grey-light: #aaa9a9;
$grey-medium: #4a4a4a80;
