@import '../../../Stylesheet/abstracts/variables';

.CreateUser {
  &.-close {
    text-align: right;

    & svg {
      cursor: pointer;
    }
  }

  &.-title {
    margin-bottom: 5px;
    letter-spacing: 0.4px;
  }

  &.-form {
    margin-top: 40px;

    & .input__icon {
      padding-top: 7px;
    }

    &-btn {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 30px auto;
      max-width: 400px;
    }
  }
}
