@import '../../Stylesheet/abstracts/variables';

.Login {
  &.-head {
    background-image: url(../../Assets/images/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    animation: breath 30s linear infinite;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    overflow-y: auto;
  }

  &.-logo {
    width: 200px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: 20px;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.-body {
    background: #60c0ed1f;
    box-shadow: 0px 4px 9px 5px #00000029;
    border-radius: 15px;
    min-height: 300px;
    width: 600px;
    max-width: 100%;
    margin: 80px auto;
    padding: 20px;
  }

  &.-form {
    background: #f7f7f7d1;
    box-shadow: 0px 4px 9px 5px #00000029;
    border-radius: 15px;
    min-height: 400px;
    padding: 10px;
    padding-top: 30px;

    & h2 {
      color: $brand-text-color;
      margin-left: 10px;
      margin-top: 20px;
    }

    & form {
      margin-top: 50px;

      & .input__icon {
        padding-top: 7px;
      }
    }
  }

  &.-btn {
    margin: 20px auto;
    text-align: center;

    & button {
      min-width: 45%;
    }
  }
}

@keyframes breath {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 600px) {
  .Login {
    &.-body {
      width: auto;
    }
  }
}
