@import '../../Stylesheet/abstracts/variables';

$brand-user-dark: #134e77;

.Users {
  &.-head {
    max-width: 800px;
    margin: 50px auto;
  }

  &.-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.-body {
    min-height: 300px;
    border-radius: 15px;
    margin: 30px 0;
  }

  &.-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    background-color: #60c0ed1f;

    & svg {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      & path {
        fill: $brand-user-dark;
      }
    }
  }

  &.-block {
    &-item {
      background-color: $brand-user-dark;
      min-height: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      color: $white;
      padding: 0px 20px;
      border-radius: 10px;
      margin-bottom: 20px;

      &-type {
        border: 0.6px solid $white;
        border-radius: 7px;
        padding: 10px 12px;
        cursor: pointer;
      }

      &-controls {
        & button {
          background-color: $red;
        }
      }
    }
  }
}
