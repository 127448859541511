@import '../../Stylesheet/abstracts/variables';

.Projects {
  &.-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;

    &-icon {
      margin-bottom: 20px;

      & svg {
        width: 150px;
        height: 150px;
        max-width: 100%;
      }
    }

    &-cta {
      margin-top: 20px;
    }
  }

  &.-cta {
    text-align: right;
    margin-top: 20px;
    margin-right: 25px;
  }

  &.-content {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
