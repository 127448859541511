@import '../../Stylesheet/abstracts/variables';

.Project {
  &.-head {
    margin: 30px auto;
    max-width: 800px;
  }

  &.-header {
    & img {
      border-radius: 15px;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
      border: 0.6px solid #091c3e10;
    }
  }

  &.-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    &-delete {
      margin-left: 20px;
      background-color: $red;
    }
  }

  &.-content {
    &-item {
      margin-top: 40px;
    }

    &-text {
      line-height: 1.5rem;
    }

    &-gallery {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      max-width: 100%;
      overflow: auto;

      &-item {
        display: flex;
        align-items: flex-end;

        & img {
          max-width: 300px;
          max-height: 300px;
          border-radius: 10px;
          cursor: pointer;
          border: 0.6px solid #091c3e10;
        }

        & svg {
          cursor: pointer;
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
    }
  }
}
