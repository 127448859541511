@import '../../Stylesheet/abstracts/variables';

.ExpandImg {
  &.-open {
    & > .ExpandImg.-background {
      height: 100%;
      opacity: 1;
    }

    & > .ExpandImg.-img {
      transform: scale(1);
    }
  }

  &.-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1050;
    position: fixed;
    height: 100%;
    background-color: #00000093 !important;
    transition: all 0.55s ease;
    opacity: 0;
    height: 0;
    overflow-y: auto;
    padding: 0 5%;
  }

  &.-img {
    max-width: 80%;
    max-height: 80%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    margin: auto;
    z-index: 1100;
    transform: scale(0);
    transition: all 0.35s ease;

    & svg {
      cursor: pointer;
      position: fixed;
      right: 0;
      top: 0;
    }

    & img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
