@import '../../Stylesheet/abstracts/variables';

.Modal {
  &.-open {
    & > .Modal.-background {
      height: 100%;
      opacity: 1;

      & > .Modal.-body {
        transform: translateY(0px);
      }
    }
  }

  &.-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1050;
    position: fixed;
    height: 100%;
    background-color: #00000093 !important;
    transition: all 0.55s ease;
    opacity: 0;
    height: 0;
    overflow-y: auto;
    padding: 0 5%;
  }

  &.-body {
    background-color: #fff;
    min-height: 300px;
    padding: 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    z-index: 1100;
    height: auto;
    margin: auto;
    margin-top: 20%;
    transform: translateY(2300px);
    transition: transform 0.35s ease;

    &.-small {
      max-width: 400px;
    }

    &.-medium {
      max-width: 600px;
    }

    &.-large {
      max-width: 800px;
    }
  }
}
