@import '../../Stylesheet/abstracts/variables';

.Dashboard {
  &.-header {
    background-image: url(../../Assets/images/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    animation: breath 30s linear infinite;
    min-height: 80px;
    margin: -10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    & img {
      width: 150px;
      max-width: 100%;
    }
  }

  &.-nav {
    background: #f3f3f3;
    box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.16);
    min-height: 80px;
    margin: 0 -10px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      display: flex;
      align-items: flex-end;
      color: #000;
      text-decoration: none;
      margin-right: 25px;
      font-size: 12px;

      &:hover {
        color: $brand-color-dark;
      }

      & svg {
        margin-right: 10px;
      }

      &--active {
        color: $brand-color-dark;
      }
    }

    &-group {
      display: flex;
      align-items: center;

      &.-logout {
        & .-nav-item {
          color: $red;
          cursor: pointer;
        }
      }
    }
  }

  &.-body {
    min-height: 500px;
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Dashboard {
    &.-nav {
      padding: 0 7px;
    }
  }
}

@media (max-width: $breakpoint-tiny-screens) {
  .Dashboard {
    &.-nav {
      &-item {
        margin-right: 15px;
      }
    }
  }
}
