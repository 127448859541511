@import '../../../Stylesheet/abstracts/variables';

.EditProject {
  &.-close {
    text-align: right;

    & svg {
      cursor: pointer;
    }
  }

  &.-title {
    margin-bottom: 5px;
    letter-spacing: 0.4px;
  }

  &.-form {
    margin-top: 60px;

    &-btn {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 30px auto;
      max-width: 400px;
    }

    &-item {
      .filepond--root {
        margin-top: 20px;
      }

      & .filepond--credits {
        display: none;
      }
    }
  }

  &.-wrapper {
    margin-top: 20px;
  }

  &.-editor {
    min-height: 150px;
    border: 1px solid #00000014;
    margin-bottom: 25px;
  }
}
